<template >
    <div class="mb-5">
      <div class="app-modal__box">
        <div class="app-modal__in">
          <div class="app-modal__header d-flex f-between p-5 m-title-modal">
            <p class="large--title m-0"> {{ $t('message.update_package') }}</p>
            <div>
               <crm-store-update-close
                  :permission="$options.name"
                  :button_type="'store'"
                  :loading="loadingButton"
                  @c-submit="submit(true)"
                  @c-close="close()"
              ></crm-store-update-close>
            </div>
          </div>
        </div>
        <!-- app-modal__header end -->
  
        <div class="app-modal__body p-5 pb-0">
            <el-form ref="form" :model="form" label-position="top" :rules="rules">
                <el-row :gutter="20">
                    <el-col :span="8">
                        <el-form-item :label="$t('message.containerType')" prop="container_type_id">
                            <select-container-type
                                :size="'large'"
                                :add_query="{user_id: authUser.id}"
                                :placeholder="$t('message.containerType')"
                                :id="form.container_type_id"
                                v-model="form.container_type_id"
                            >
                            </select-container-type>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item :label="$t('message.sender_warehouse')" prop="from_filial_id">
                            <select-from-filial 
                                v-model="form.from_filial_id" 
                                :id="form.from_filial_id" 
                                :select_only="true"
                                :disabled="true"
                                :placeholder="$t('message.sender_warehouse')" 
                                class="select__width w-100" 
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item :label="$t('message.reciever_warehouse')" prop="to_filial_id">
                            <select-to-filial 
                                v-model="form.to_filial_id" 
                                :id="form.to_filial_id" 
                                :show_all="1"
                                :disabled="true"
                                :placeholder="$t('message.sender_warehouse')" 
                                class="select__width w-100" 
                            />
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row :gutter="20">
                    <el-col :span="12" v-if="form.from_filial_id && form.to_filial_id">
                        <el-form-item :label="$t('message.client')" prop="client_id">
                            <select-client
                                :size="'large'"
                                class="w-100"
                                :placeholder="$t('message.client')"
                                :id="form.all_client_id"
                                v-model="form.all_client_id"
                                >
                            </select-client>
                        </el-form-item>
                    </el-col>
                    
                    <el-col :span="12" v-if="form.from_filial_id && form.to_filial_id">
                        <el-form-item :label="$t('message.deal')" prop="deal_id">
                            <select-deal
                                v-model="form.deal_id"
                                :id="form.deal_id"
                                :from_filial_id="form.from_filial_id"
                                :to_filial_id="form.to_filial_id"
                                :all_client_id="form.all_client_id"
                                :query="{has_in_sending_filial: true}"
                                :size="'large'"
                                :placeholder="$t('message.deal')"
                            ></select-deal>
                        </el-form-item>
                    </el-col>
                    
                </el-row>
            </el-form>
        </div>
        <div class="app-modal__body p-5 pb-0 mt-2 mm_custom_input_padding" v-loading="loadingData">
            <span class="font-bold mb-2">{{$t('message.number_of_cargo')}}: {{totalQuantity}}</span>
            <div>
                <el-table 
                    header-row-class-name="header__class"
                    row-class-name="row__class"
                    :data="dealProductsList" 
                    show-summary 
                    :summary-method="getSummaries" 
                    border stripe 
                    style="width: 100%">
                    <el-table-column
                        type="index"
                        width="50">
                    </el-table-column>
                    <el-table-column :label="$t('message.deal')" width="67">
                        <template slot-scope="scope"> {{ scope.row.deal_id }} </template>
                    </el-table-column>
                    <el-table-column :label="$t('message.name')">
                        <template slot-scope="scope"> {{ scope.row.product.name }} </template>
                    </el-table-column>
                    <el-table-column :label="$t('message.barcode')">
                        <template slot-scope="scope"> {{ scope.row.barcode }} </template>
                    </el-table-column>
                    <el-table-column :label="$t('message.quantity_y')" width="80">
                        <template slot-scope="scope"> {{ scope.row.remainder }} </template>
                    </el-table-column>
                    <el-table-column :label="$t('message.current_quantity')" width="95">
                        <template slot-scope="scope">
                            <el-input
                                :disabled="(scope.row.batches && scope.row.batches.length > 1)"
                                :min="0"
                                type="number"
                                size="mini"
                                @input="checkValue(scope.row)"
                                v-model="scope.row.incoming_quantity"
                            ></el-input>
                        </template>
                    </el-table-column>

                    <el-table-column :label="columns.weight.title" width="130">
                        <template slot-scope="scope">
                            <div v-show="(!scope.row.batches || scope.row.batches.length <=1 )">
                                <el-input 
                                    class="mm_custom_input"
                                    :min="0" 
                                    :disabled="true"
                                    type="number" 
                                    size="mini" 
                                    v-model="scope.row.weight"
                                >
                                    <template slot="append"></template>
                                </el-input>
                                <el-input
                                    type="text"
                                    size="mini"
                                    disabled
                                    :value="compareAndSetMeasureWord(scope.row)"
                                ></el-input>
                            </div>
                            <span v-show="scope.row.batches && scope.row.batches.length > 1"> {{ showWeight(scope.row) }} 
                                / {{ parseFloat((showWeight(scope.row) * $kg_to_pound)).toFixed(6) }} 
                            </span>
                        </template>
                    </el-table-column>

                    <el-table-column :label="columns.item_weight.title" width="130">
                        <template slot-scope="scope">
                            <el-input 
                                v-show="(!scope.row.batches || scope.row.batches.length <=1 )"
                                class="mm_custom_input"
                                :min="0" 
                                :disabled="showInputCheck(scope.row)"
                                @input="updatePoundAndKG(scope.row, 'weight_in_kg')"
                                type="number" 
                                size="mini" 
                                v-model="scope.row.item_weight"
                            >
                                <template slot="append">{{ $t('message.kg') }}</template>
                            </el-input>

                            <el-input 
                                v-show="(!scope.row.batches || scope.row.batches.length <=1 && !showInputCheck(scope.row))"
                                class="mm_custom_input"
                                :min="0" 
                                @input="updatePoundAndKG(scope.row, 'weight_in_pounds')"
                                type="number" 
                                size="mini" 
                                v-model="scope.row.item_weight_in_pounds"
                            >
                                <template slot="append">{{ $t('message.lbs') }}</template>
                            </el-input>
                        </template>
                    </el-table-column>

                    <el-table-column :label="columns.width.title" width="110">
                        <template slot-scope="scope">
                            <el-input
                                v-show="showInputCheck(scope.row)"
                                class="mm_custom_input"
                                :min="0"
                                type="number"
                                size="mini"
                                @input="updateWeightAndSizes(scope.row, 'width')"
                                v-model="scope.row.width"
                            >
                                <template slot="append">{{ $t('message.sm') }}</template>
                            </el-input>

                            <el-input
                                v-show="showInputCheck(scope.row)"
                                class="mm_custom_input"
                                :min="0"
                                type="number"
                                size="mini"
                                @input="updateWeightAndSizes(scope.row, 'width_on_inches')"
                                v-model="scope.row.width_on_inches"
                            >
                                <template slot="append">{{ $t('message.inch') }}</template>
                            </el-input>
                        </template>
                    </el-table-column>
                    <el-table-column :label="columns.height.title" width="110">
                        <template slot-scope="scope">
                            <el-input
                                v-show="showInputCheck(scope.row)"
                                class="mm_custom_input"
                                :min="0"
                                type="number"
                                size="mini"
                                @input="updateWeightAndSizes(scope.row, 'height')"
                                v-model="scope.row.height"
                            >
                                <template slot="append">{{ $t('message.sm') }}</template>
                            </el-input>

                            <el-input
                                v-show="showInputCheck(scope.row)"
                                class="mm_custom_input"
                                :min="0"
                                type="number"
                                size="mini"
                                @input="updateWeightAndSizes(scope.row, 'height_on_inches')"
                                v-model="scope.row.height_on_inches"
                            >
                                <template slot="append">{{ $t('message.inch') }}</template>
                            </el-input>
                        </template>
                    </el-table-column>
                    <el-table-column :label="columns.length.title" width="110">
                        <template slot-scope="scope">
                            <el-input
                                v-show="showInputCheck(scope.row)"
                                class="mm_custom_input"
                                :min="0"
                                type="number"
                                size="mini"
                                @input="updateWeightAndSizes(scope.row, 'length')"
                                v-model="scope.row.length"
                            >
                                <template slot="append">{{ $t('message.sm') }}</template>
                            </el-input>

                            <el-input
                                v-show="showInputCheck(scope.row)"
                                class="mm_custom_input"
                                :min="0"
                                type="number"
                                size="mini"
                                @input="updateWeightAndSizes(scope.row, 'length_on_inches')"
                                v-model="scope.row.length_on_inches"
                            >
                                <template slot="append">{{ $t('message.inch') }}</template>
                            </el-input>
                        </template>
                    </el-table-column>

                    <el-table-column :label="$t('message.comment')">
                        <template slot-scope="scope"> {{ scope.row.comment }} </template>
                    </el-table-column>

                    <el-table-column :label="$t('message.update')" width="85">
                        <template slot-scope="scope"> 
                            <el-button 
                                :disabled="scope.row.batches && scope.row.batches.length <= 1" 
                                @click="showAndUpdateBatches(scope.row)" 
                                type="primary" icon="el-icon-edit" circle>
                            </el-button> 
                        </template>
                    </el-table-column>

                    <el-table-column width="70">
                        <template slot-scope="scope">
                            <i @click="removeFromDealProductsList(scope.row, scope.$index, 'return')" class="el-icon-refresh-left icon__primary"></i>
                            <i @click="removeFromDealProductsList(scope.row, scope.$index, 'delete')" class="el-icon-delete icon__delete"></i>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="mt-4 pallet__flex">
                <select-product
                    class="w-100 mr-5"
                    :placeholder="$t('message.product_id')"
                    :deal_id="form.deal_id"
                    :disabled="form.deal_id ? false : true"
                    :id="product_id"
                    :from_filial_id="form.from_filial_id"
                    :to_filial_id="form.to_filial_id"
                    :country_id="form.country_id"
                    v-model="product_id"
                    :scan="false"
                    :show_package="false"
                    @updateDealAndAddProduct="updateDealAndAddProduct"
                    @get_selected_product="get_selected_product"
                >
                </select-product>
            </div>
        </div>
        <!-- end app-modal__body -->
      </div>
        <el-dialog 
            :append-to-body="true"
            :title="$t('message.product_inventorization')" 
            width="70%"
            :visible.sync="batchDialog">
            <div v-if="selectedProduct.batches" class="mm_custom_input_padding">
                <el-table :data="selectedProduct.batches">
                    <el-table-column :label="$t('message.name')">
                        <template> {{ selectedProduct.product ? selectedProduct.product.name : '' }} </template>
                    </el-table-column>

                    <el-table-column :label="$t('message.barcode')">
                        <template slot-scope="scope"> {{ scope.row.barcode }} </template>
                    </el-table-column>
                    
                    <el-table-column :label="$t('message.quantity_y')"  width="80">
                        <template slot-scope="scope"> {{ scope.row.remainder }} </template>
                    </el-table-column>
                    
                    <el-table-column :label="$t('message.current_quantity')"  width="95">
                        <template slot-scope="scope">
                            <el-input
                                :min="0"
                                type="number"
                                size="mini"
                                @input="checkValue(scope.row)"
                                v-model="scope.row.incoming_quantity"
                            ></el-input>
                        </template>
                    </el-table-column>

                    <el-table-column :label="columns.weight.title"  width="130">
                        <template slot-scope="scope">
                            <el-input 
                                class="mm_custom_input"
                                :min="0" 
                                type="number" 
                                size="mini"
                                :disabled="true"
                                v-model="scope.row.weight"
                            >
                                <template slot="append"></template>
                            </el-input>
                            <el-input
                                type="text"
                                size="mini"
                                disabled
                                :value="compareAndSetMeasureWord(scope.row)"
                            ></el-input>
                        </template>
                    </el-table-column>

                    <el-table-column :label="columns.item_weight.title"  width="130">
                        <template slot-scope="scope">
                            <el-input 
                                class="mm_custom_input"
                                :min="0" 
                                type="number" 
                                size="mini" 
                                @input="updatePoundAndKG(scope.row, 'weight_in_kg')"
                                v-model="scope.row.item_weight"
                            >
                                <template slot="append">{{ $t('message.kg') }}</template>
                            </el-input>

                            <el-input 
                                class="mm_custom_input"
                                :min="0" 
                                type="number" 
                                size="mini" 
                                @input="updatePoundAndKG(scope.row, 'weight_in_pounds')"
                                v-model="scope.row.item_weight_in_pounds"
                            >
                                <template slot="append">{{ $t('message.lbs') }}</template>
                            </el-input>
                        </template>
                    </el-table-column>

                    <el-table-column :label="columns.width.title" v-if="columns.width.show" width="110">
                        <template slot-scope="scope">
                            <el-input
                                class="mm_custom_input"
                                :min="0"
                                type="number"
                                size="mini"
                                @input="updateWeightAndSizes(scope.row, 'width')"
                                v-model="scope.row.width"
                            >
                                <template slot="append">{{ $t('message.sm') }}</template>
                            </el-input>

                            <el-input
                                class="mm_custom_input"
                                :min="0"
                                type="number"
                                size="mini"
                                @input="updateWeightAndSizes(scope.row, 'width_on_inches')"
                                v-model="scope.row.width_on_inches"
                            >
                                <template slot="append">{{ $t('message.inch') }}</template>
                            </el-input>
                        </template>
                    </el-table-column>
                    <el-table-column :label="columns.height.title" v-if="columns.height.show" width="110">
                        <template slot-scope="scope">
                            <el-input
                                class="mm_custom_input"
                                :min="0"
                                type="number"
                                size="mini"
                                @input="updateWeightAndSizes(scope.row, 'height')"
                                v-model="scope.row.height"
                            >
                                <template slot="append">{{ $t('message.sm') }}</template>
                            </el-input>

                            <el-input
                                class="mm_custom_input"
                                :min="0"
                                type="number"
                                size="mini"
                                @input="updateWeightAndSizes(scope.row, 'height_on_inches')"
                                v-model="scope.row.height_on_inches"
                            >
                                <template slot="append">{{ $t('message.inch') }}</template>
                            </el-input>
                        </template>
                    </el-table-column>
                    <el-table-column :label="columns.length.title" v-if="columns.length.show" width="110">
                        <template slot-scope="scope">
                            <el-input
                                class="mm_custom_input"
                                :min="0"
                                type="number"
                                size="mini"
                                @input="updateWeightAndSizes(scope.row, 'length')"
                                v-model="scope.row.length"
                            >
                                <template slot="append">{{ $t('message.sm') }}</template>
                            </el-input>

                            <el-input
                                class="mm_custom_input"
                                :min="0"
                                type="number"
                                size="mini"
                                @input="updateWeightAndSizes(scope.row, 'length_on_inches')"
                                v-model="scope.row.length_on_inches"
                            >
                                <template slot="append">{{ $t('message.inch') }}</template>
                            </el-input>
                        </template>
                    </el-table-column>  
                    <el-table-column :label="$t('message.comment')">
                        <template > {{ selectedProduct.comment }} </template>
                    </el-table-column>
                </el-table>

                <div class="mt-4 cargo__right">
                    <el-button :loading="loadingButton" @click="updateIncomingProducts()" type="primary" plain>{{ $t('message.save') }}</el-button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>
  
<script>
  import { mapGetters, mapActions } from "vuex";
  import drawer from "@/utils/mixins/drawer";
  import form from "@/utils/mixins/form";
  import selectDeal from "@/components/selects/select-deal";
  import selectFromFilial from "@/components/selects/select-from-filial";
  import selectToFilial from "@/components/selects/select-to-filial";
  import selectClient from "@/components/inventory/select-client";
  import selectProduct from "@/components/inventory/select-deal-filial-product";
  import selectContainerType from "@/components/inventory/select-container-type";
  import { formatMoney, formatNumber } from "@/filters/index";
  import product_calculation_functions from "@/utils/mixins/product_calculation_functions";
  import { successVoice, errorVoice } from '@/utils/audioHelper';

  import _ from 'lodash';

  
  export default {
    mixins: [form, drawer, product_calculation_functions],
    props:{
        selectedPackage:{
            type: Object    
        }
    }, 
    components: {
        selectDeal,
        selectFromFilial,
        selectToFilial,
        selectClient,
        selectProduct,
        selectContainerType,
    },
    data() {
      return {
        mode: true,
        loadingButton: false,
        loadingData: false,
        dealProductList: [],
        datePickerOptions: {
            disabledDate (date) {
                return date > new Date()
            }
        },
        deal_id: null,
        product_id: null,
        dealProductsList: [],
        batchDialog: false,
        selectedProduct: {}
      };
    },
    computed: {
        ...mapGetters({
            authUser: "auth/user",
            rules: "packageForProductCirculations/rules",
            model: "productCirculations/model",
            columns: "dealProducts/columns",
            dealProducts: "dealProducts/filialDealProducts",
            products: "packageForProductCirculations/products",
            model: "packageForProductCirculations/model",
        }),
        totalQuantity() {
            let sum = 0;
            if(this.dealProductList.length > 0){
                sum = this.dealProductList.reduce((accumulator, object) => {
                    return accumulator + object.incoming_quantity;
                }, 0);
            }
            return sum;
        },
        total_weight: function () {
            let total = 0;
            this.dealProductsList.forEach((product) => {
                if(product.batches.length > 1){
                    product.batches.forEach(batch => {
                        total += parseFloat(batch.weight);
                    });
                }else{
                    total += parseFloat(product.weight);
                }
            });
            return parseFloat(total);
        },
    },
    methods: {
        ...mapActions({
            save: "packageForProductCirculations/update",
            show: "packageForProductCirculations/show", 
            showDealProducts: "dealProducts/filialDealProducts",
            updateList: "packageForProductCirculations/index",
            updateProductList: "productCirculations/index",
            showProducts: "packageForProductCirculations/showProducts",
            destroy: "packageForProductCirculations/destroy",
            deletePackageProduct: "packageForProductCirculations/deletePackageProduct", 
        }),
        afterOpen(){
            this.show(this.selectedPackage.id)
                .then(res => {
                    this.form = JSON.parse(JSON.stringify(this.model));
                    this.dealProductsList = this.form.products;
                });
        },
        afterLeave(){
            if(this.form.products && this.form.products.length === 0){
                this.destroy({id: this.selectedPackage.id})
                    .then((res) => {
                        this.$alert(res);
                        this.updateList({circulated: false});
                    })
                    .catch((err) => {
                        this.$alert(err);
                    });
            }
            this.form = JSON.parse(JSON.stringify(this.model));
            this.dealProductsList = [];
        },
        updateDealAndAddProduct(val) {
            if(val){
                this.deal_id = val;
            }
        },
        async updateIncomingProducts(){
            this.loadingButton = true;
            await this.updateBatches();
            this.loadingButton = false;
            this.selectedProduct = {};
            this.batchDialog = false;
        },
        updateBatches(){
            if(this.selectedProduct && this.selectedProduct.batches){
                this.dealProductsList.forEach(el => {
                    if(el.id === this.selectedProduct.id){
                        this.$set(el, 'batches', this.selectedProduct.batches);
                        
                        let total_quantity = 0;
                        this.selectedProduct.batches.forEach(batch => {
                            total_quantity +=  parseFloat(batch.incoming_quantity);
                        });
                       
                        this.$set(el, 'incoming_quantity', parseFloat(total_quantity));
                    }
                });
            }
        },
        showAndUpdateBatches(row){
            this.batchDialog = true;
            this.selectedProduct = JSON.parse(JSON.stringify(row));
        },
        showWeight(row){
            if(row.batches && row.batches.length > 1){
                let weights = 0;
                row.batches.forEach(element => {
                    weights += parseFloat(element.weight); 
                });
                return _.round(weights, 3);
            }else{
                return row.weight;
            }
        },
        showInputCheck(row){
            if(!row.batches || row.batches.length <= 1) {
                return true;
            }
            return false;
        },
        checkValue(row){
            if(parseFloat(row.incoming_quantity) > parseFloat(row.remainder) || parseFloat(row.incoming_quantity) < 0){
                this.$set(row, 'incoming_quantity', parseFloat(row.remainder))
            }
            if(!row.incoming_quantity){
                this.$set(row, 'incoming_quantity', parseFloat(0))
            }
        },
        get_selected_product({product, barcode}){
            if(product && this.dealProductsList.filter(el => el.id === product.id).length === 0){
                let prod = JSON.parse(JSON.stringify(product));
                
                if(barcode && prod.batches && prod.batches.length <= 1){
                    successVoice();
                    this.$set(prod, 'incoming_quantity', parseFloat(1));
                }else if(barcode && prod.batches && prod.batches.length > 1){
                    successVoice();
                    let index = prod.batches.map(e => e.barcode).indexOf(barcode);
                    this.$set(prod, 'incoming_quantity', parseFloat(1));
                    this.$set(prod.batches[index], 'incoming_quantity', parseFloat(1));
                }else{
                    this.$set(prod, 'incoming_quantity', parseFloat(0));
                }

                this.dealProductsList.push(prod);
                this.product_id = null;
            }else if(product && barcode){
                let index = this.dealProductsList.map(e => e.id, product.id).indexOf(product.id);
                if(this.dealProductsList[index].batches && this.dealProductsList[index].batches.length > 1){
                    this.dealProductsList[index].batches.forEach(el => {
                        if(el.barcode === barcode){
                            if(el.incoming_quantity < el.remainder) {
                                successVoice();
                                el.incoming_quantity ++;
                                this.dealProductsList[index].incoming_quantity ++;
                            }else{
                                this.insufficiant_prod_message();
                            }
                            
                        }
                    })
                }else{
                    if(this.dealProductsList[index].incoming_quantity < this.dealProductsList[index].remainder) {
                        successVoice();
                        this.dealProductsList[index].incoming_quantity ++;
                    }else{
                        this.insufficiant_prod_message();
                    }
                }
            }else{
                errorVoice();
                this.$notify({
                    title: this.$t('message.product'),
                    type: "warning",
                    offset: 130,
                    message: this.$t('message.product_already_exists')
                });
            }
        },
        insufficiant_prod_message(){
            errorVoice();
            this.$notify({
                title: this.$t('message.product'),
                type: "error",
                offset: 130,
                message: this.$t('message.insufficient_goods')
            });
        },
        submit(close = true) {
            this.form.products = this.dealProductsList;
            let check_empty_prods = this.dealProductsList.filter(el => el.incoming_quantity === 0);
            if(this.dealProductsList.length > 0 && check_empty_prods.length === 0 && this.total_weight > 0){
                this.$refs["form"].validate((valid) => {
                    if (valid) {
                        this.loadingButton = true;
                        this.save(this.form)
                            .then((res) => {
                                this.loadingButton = false;
                                this.$alert(res);
                                this.updateList({circulated: false});
                                this.updateProductList({circulated: false});
                                this.parent().listChanged();
                                if (close) this.close();
                            })
                            .catch((err) => {
                                this.loadingButton = false;
                                this.$alert(err);
                            });
                    }
                });
            }else{
                this.$notify({
                    title: this.$t('message.warning'),
                    type: "warning",
                    offset: 130,
                    message: this.$t('message.check_prod_quantity')
                });
            }

        },
        removeFromDealProductsList(row, index, type = 'delete') {
            let warn_text = this.$t('message.product_will_be_removed_from_sender_warehouse');
            if(type === 'return') warn_text = this.$t('message.products_will_be_returned_back_to_sender_filial');
            if (row.packed) {
                this.$confirm(
                    warn_text,
                    this.$t('message.warning'),
                    {
                    confirmButtonText: this.$t('message.yes'),
                    cancelButtonText: this.$t('message.no'),
                    type: "warning",
                    }
                )
                .then(() => {
                    this.deletePackageProduct({id: this.selectedPackage.id, deal_product_id: row.id, type: type})
                        .then((res) =>{
                            this.dealProductsList.splice(index, 1);
                            this.$alert(res);
                        })
                        .catch((err) => {
                            this.$alert(err);
                        })
                })
                .catch(() => {
                    this.$message({
                        type: "warning",
                        message: this.$t('message.deleting_product_canceled'),
                    });
                });
            }else{
                this.dealProductsList.splice(index, 1);
            }
        },
        getSummaries(param) {
            const { columns } = param;
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 1) {
                    sums[index] = this.$t('message.total');
                    return;
                }
                if (column.label === this.columns.weight.title) {
                    sums[index] = formatNumber(this.total_weight, 1) + ' ' + this.$t('message.kg');
                    return;
                }
               
                if (column.label === (this.columns.weight.title + ' %')) {
                    sums[index] = formatMoney(this.weight, 2);
                    return;
                }
            });

            return sums;
        },


        
    },
  };
  </script>