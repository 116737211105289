<template >
    <div class="mb-5">
        <div class="app-modal__box">
            <div class="app-modal__in">
            <div class="app-modal__header d-flex f-between p-5 m-title-modal">
                <p class="large--title m-0"> {{ $t("message.add_cargo") }}</p>
                <div>
                    <crm-store-update-close
                        :permission="$options.name"
                        :button_type="'store'"
                        :loading="loadingButton"
                        @c-submit="submit(true)"
                        @c-close="close()"
                    ></crm-store-update-close>
                </div>
            </div>
            </div>
            <!-- app-modal__header end -->
    
            <el-form ref="form" class="mm_custom_input_padding" :model="form" label-position="top" :rules="rules">
                <div class="app-modal__body p-5 pb-0">
                    <el-row :gutter="20">
                        <el-col :span="6">
                            <el-form-item :label="$t('message.sender_warehouse')" prop="from_filial_id">
                                <select-from-filial 
                                    v-model="form.from_filial_id" 
                                    :id="form.from_filial_id" 
                                    :select_only="true"
                                    :disabled="scanning"
                                    :placeholder="$t('message.sender_warehouse')" 
                                    class="select__width w-100" 
                                />
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item :label="$t('message.sender') + '/' + $t('message.partner')" prop="client_id">
                                <select-client
                                    :size="'large'"
                                    class="w-100"
                                    :placeholder="$t('message.sender') + '/' + $t('message.partner')"
                                    :disabled="scanning"
                                    :id="form.all_client_id"
                                    v-model="form.all_client_id"
                                    >
                                </select-client>
                            </el-form-item>
                        </el-col>
                        
                        <el-col :span="6">
                            <el-form-item :label="$t('message.deal')">
                                <select-deal
                                    v-model="form.deal_id"
                                    :id="form.deal_id"
                                    :disabled="scanning"
                                    :from_filial_id="form.from_filial_id"
                                    :to_filial_id="form.to_filial_id"
                                    :all_client_id="form.all_client_id"
                                    :query="{has_to_inventor_in_filial: true}"
                                    :size="'large'"
                                    :placeholder="$t('message.deal')"
                                ></select-deal>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item :label="$t('message.date')" prop="date">
                                <el-date-picker
                                    class="w-100"
                                    v-model="form.date"
                                    type="datetime"
                                    :placeholder="$t('message.date')"
                                    format="yyyy-MM-dd HH:mm"
                                    value-format="yyyy-MM-dd HH:mm"
                                    :clearable="false"
                                    :picker-options="datePickerOptions"
                                    default-time="10:00">
                                </el-date-picker>
                            </el-form-item>
                        </el-col>
                        
                    </el-row>
                    <el-input class="mr-5" ref="scanRef" @input="scanClientProductBarcodeDebounce" :placeholder="$t('message.scan')" v-model="barcode_search" size="mini"></el-input>
                </div>
                <div class="mb-4 cargo__right">
                </div>
                <div class="app-modal__body p-5 pb-0 mt-2" v-loading="loadingData">
                    <div>
                        <span class="font-bold">{{$t('message.number_of_cargo')}}: {{totalQuantity}}</span>
                        <div v-if="checkSelectedProds()" style="float: right;" class="d-flex">
                            <el-form-item prop="container_type_id">
                                <select-container-type
                                    :size="'large'"
                                    :add_query="{user_id: authUser.id}"
                                    :placeholder="$t('message.containerType')"
                                    v-model="form.container_type_id"
                                >
                                </select-container-type>
                            </el-form-item>
                            <div>
                                <el-button :loading="loadingButton" @click="submit(false, true)" size="large" class="ml-4" type="primary" plain>
                                    {{ $t('message.pack_product') }}
                                </el-button>
                            </div>
                        </div>
                    </div>
                    <template>
                        <el-table
                            ref="productTable"
                            :data="dealProductList"
                            border 
                            style="width: 100%"
                            :row-class-name="tableRowClassName"
                            @selection-change="handleSelectionChange"
                            >
                                <el-table-column
                                    type="index"
                                    width="50">
                                </el-table-column>
                                <el-table-column
                                    type="selection"
                                    width="55">
                                </el-table-column>
                                <el-table-column :label="$t('message.deal')" width="75">
                                    <template slot-scope="scope">
                                        {{ scope.row.deal_id }}
                                    </template>
                                </el-table-column>
                                <el-table-column :label="$t('message.name')">
                                    <template slot-scope="scope">
                                        <span v-if="scope.row.type &&  scope.row.type === 'package'">{{ $t('message.package') }}</span>
                                        <span v-else>{{ scope.row.product ? scope.row.product.name : '' }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column :label="$t('message.comment')">
                                    <template slot-scope="scope">
                                        <span v-if="scope.row.type && scope.row.type === 'package'">{{ scope.row.containerType ? scope.row.containerType.name : '' }}</span>
                                        <span v-else>{{ scope.row.comment }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="barcode" :label="$t('message.barcode')">
                                    <template slot-scope="scope">
                                        <span>{{ scope.row.barcode }}</span>
                                        <span v-if="scope.row.tracking_code" class="d-block" style="border-top: 1px solid black">
                                            {{ scope.row.tracking_code }}
                                        </span>
                                    </template>
                                </el-table-column>
                                <el-table-column :label="$t('message.quantity_y')" width="80">
                                    <template slot-scope="scope">
                                        <span v-if=" scope.row.type &&  scope.row.type === 'package'"> 1 </span>
                                        <span v-else>{{ scope.row.quantity }}</span>
                                    </template>
                                </el-table-column>

                                <el-table-column :label="$t('message.incoming_quantity')" width="95">
                                    <template slot-scope="scope">
                                        <span v-if=" scope.row.type &&  scope.row.type === 'package'"> 0 </span>
                                        <span v-else>{{ scope.row.old_incoming_quantity }}</span>
                                    </template>
                                </el-table-column>

                                <el-table-column :label="$t('message.current_quantity')" width="100">
                                    <template slot-scope="scope">
                                        <el-input 
                                            @input="updateCurrentQuantity(scope.row)" 
                                            :disabled="(scope.row.quantity - scope.row.old_incoming_quantity) <= 0"
                                            :min="0" 
                                            type="number" 
                                            size="mini" 
                                            v-model="scope.row.incoming_quantity"
                                        ></el-input>
                                    </template>
                                </el-table-column>

                                <el-table-column :label="$t('message.remainder')"  width="80">
                                    <template slot-scope="scope">
                                        <span v-if=" scope.row.type &&  scope.row.type === 'package'"> 1 </span>
                                        <span v-else > {{ scope.row.quantity - scope.row.old_incoming_quantity - scope.row.incoming_quantity }} </span>
                                    </template>
                                </el-table-column>

                                <el-table-column :label="columns.weight.title" width="150">
                                    <template slot-scope="scope">
                                        <div v-if="scope.row.type &&  scope.row.type === 'product'">
                                            <el-input 
                                                :min="0" 
                                                class="mm_custom_input"
                                                type="number" 
                                                size="mini" 
                                                v-model="scope.row.weight"
                                                :disabled="true"
                                            >
                                                <template slot="append"></template>
                                            </el-input>
                                            <el-input
                                                type="text"
                                                size="mini"
                                                disabled
                                                :value="compareAndSetMeasureWord(scope.row)"
                                            ></el-input>
                                        </div>
                                        <span v-else class="d-flex f-between">
                                            <span>
                                                {{ scope.row.total_weight }}
                                            </span>
                                            <el-button @click="updatePackProdsWeight(scope.row, scope.$index)" size="small" type="primary" icon="el-icon-edit"></el-button> 
                                        </span>
                                    </template>
                                </el-table-column>

                                <el-table-column :label="columns.item_weight.title" width="150">
                                    <template slot-scope="scope">
                                        <div v-if="scope.row.type &&  scope.row.type === 'product'">
                                            <el-input 
                                                :min="0" 
                                                class="mm_custom_input"
                                                type="number" 
                                                size="mini" 
                                                v-model="scope.row.item_weight"
                                                @input="updatePoundAndKG(scope.row, 'weight_in_kg')"
                                            >
                                                <template slot="append">{{ $t('message.kg') }}</template>
                                            </el-input>
                                            <el-input 
                                                class="mm_custom_input"
                                                :min="0" 
                                                type="number" 
                                                size="mini" 
                                                @input="updatePoundAndKG(scope.row, 'item_weight_in_pounds')"
                                                v-model="scope.row.item_weight_in_pounds"
                                            >
                                                <template slot="append">{{ $t('message.lbs') }}</template>
                                            </el-input>
                                        </div>
                                    </template>
                                </el-table-column>

                                <el-table-column :label="columns.width.title + ' x ' + columns.height.title + ' x ' + columns.length.title" width="250">
                                    <template slot-scope="scope">
                                        <div class="d-flex mm-input-without-padding">
                                            <el-input
                                                v-if="scope.row.type &&  scope.row.type === 'product'"
                                                class="mm_custom_input"
                                                :min="0"
                                                type="number"
                                                size="mini"
                                                @input="updateWeightAndSizes(scope.row, 'width')"
                                                v-model="scope.row.width"
                                            >
                                            </el-input>
                                            <el-input
                                                v-if="scope.row.type &&  scope.row.type === 'product'"
                                                class="mm_custom_input"
                                                :min="0"
                                                type="number"
                                                size="mini"
                                                @input="updateWeightAndSizes(scope.row, 'height')"
                                                v-model="scope.row.height"
                                            >
                                            </el-input>
                                            <el-input
                                                v-if="scope.row.type && scope.row.type === 'product'"
                                                class="mm_custom_input"
                                                :min="0"
                                                type="number"
                                                size="mini"
                                                @input="updateWeightAndSizes(scope.row, 'length')"
                                                v-model="scope.row.length"
                                            >
                                            </el-input>
                                            <el-button 
                                                v-if="scope.row.type && scope.row.type === 'product'"  
                                                style="border-radius: 5px !important; background-color: #F5F7FA;"
                                                size="mini" 
                                                disabled>
                                                    {{ $t('message.sm') }}
                                            </el-button>
                                        </div>
                                        <div class="d-flex mm-input-without-padding">
                                            <el-input
                                                v-if="scope.row.type &&  scope.row.type === 'product'"
                                                class="mm_custom_input"
                                                :min="0"
                                                type="number"
                                                size="mini"
                                                @input="updateWeightAndSizes(scope.row, 'width_on_inches')"
                                                v-model="scope.row.width_on_inches"
                                            >
                                            </el-input>
                                            <el-input
                                                v-if="scope.row.type &&  scope.row.type === 'product'"
                                                class="mm_custom_input"
                                                :min="0"
                                                type="number"
                                                size="mini"
                                                @input="updateWeightAndSizes(scope.row, 'height_on_inches')"
                                                v-model="scope.row.height_on_inches"
                                            >
                                            </el-input>
                                            <el-input
                                                v-if="scope.row.type &&  scope.row.type === 'product'"
                                                class="mm_custom_input"
                                                :min="0"
                                                type="number"
                                                size="mini"
                                                @input="updateWeightAndSizes(scope.row, 'length_on_inches')"
                                                v-model="scope.row.length_on_inches"
                                            >
                                            </el-input>
                                            <el-button 
                                                v-if="scope.row.type && scope.row.type === 'product'"      
                                                style="border-radius: 5px !important; background-color: #F5F7FA;"
                                                size="mini" 
                                                disabled>
                                                {{ $t('message.inch') }}
                                            </el-button>
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column :label="$t('message.services')">
                                    <template slot-scope="scope">
                                        <el-button @click="showServices(scope.row.deal_id)" size="small" type="primary" icon="fa-solid fa-tag"></el-button>  
                                    </template>
                                </el-table-column>
                                <el-table-column :label="$t('message.show')" width="90">
                                    <template slot-scope="scope">
                                        <el-button v-if="scope.row.type == 'package'"
                                            @click="showPackProds(scope.row)"
                                            size="small"
                                            type="primary" icon="el-icon-view" circle>
                                        </el-button> 
                                        <el-button v-if="scope.row.parcel"
                                            @click="updateParcelProducts(scope.row)"
                                            size="small"
                                            type="primary" icon="el-icon-view" circle>
                                        </el-button> 
                                    </template>
                                </el-table-column>
                        </el-table>
                    </template>
                </div>
            </el-form>
            <!-- end app-modal__body -->
        </div>

        <el-dialog class="dialog__modal" :append-to-body="true" :title="$t('message.products')" :visible.sync="dialogProductsList" width="60%">
            <div v-loading="loadingProducts">
                <el-table :data="packageDealProducts" border>
                    <el-table-column type="index" width="50"> </el-table-column>
                    <el-table-column prop="deal_id" :label="$t('message.deal')" width="90">
                        <template slot-scope="scope">
                            {{ scope.row.deal ? scope.row.deal.id : '' }}
                        </template> 
                    </el-table-column>
                    <el-table-column prop="name" :label="$t('message.name')">
                        <template slot-scope="scope">
                            {{ scope.row.product ? scope.row.product.name : '' }}
                        </template>
                    </el-table-column>

                    <el-table-column prop="comment" :label="$t('message.comment')">
                        <template slot-scope="scope">
                            {{ scope.row.comment ? scope.row.comment : '' }}
                        </template>
                    </el-table-column>

                    <el-table-column prop="quantity" :label="$t('message.quantity')"></el-table-column>

                    <el-table-column prop="weight" :label="$t('message.weight_in_kg')"></el-table-column>
                    <el-table-column prop="weight_in_pounds" :label="$t('message.weight_in_pounds')"></el-table-column>
                    
                </el-table>
            </div>
        </el-dialog>

        <el-dialog 
            class="dialog__modal" 
            :title="$t('message.products')" 
            :append-to-body="true"
            :visible.sync="dialogParcelProductsList" 
            width="60%" 
            @opened="$refs['parcelProducts'] ? $refs['parcelProducts'].afterOpen() : ''">
                <parcel-products-list ref="parcelProducts" :selectedItem="selectedProd"></parcel-products-list>
        </el-dialog>

        <el-dialog 
            class="dialog__modal" 
            :title="$t('message.products')" 
            :append-to-body="true"
            :visible.sync="searchedProductDialog" 
            width="60%">
            <div v-loading="searchLoading">
                <el-table :data="barcodeSearchProducts" border>
                    <el-table-column type="index" width="50"> </el-table-column>
                    
                    <el-table-column prop="deal_id" :label="$t('message.deal')" width="90">
                        <template slot-scope="scope">
                            {{ scope.row.deal_id }}
                        </template> 
                    </el-table-column>

                    <el-table-column prop="name" :label="$t('message.name')">
                        <template slot-scope="scope">
                            {{ scope.row.product ? scope.row.product.name : '' }}
                        </template>
                    </el-table-column>

                    <el-table-column prop="reciever" :label="$t('message.reciever')">
                        <template slot-scope="scope">
                            {{ scope.row.reciever ? scope.row.reciever.custom_id : '' }},
                            <br>
                            {{ scope.row.reciever ? scope.row.reciever.name : '' }}
                        </template>
                    </el-table-column>

                    <el-table-column prop="comment" :label="$t('message.comment')">
                        <template slot-scope="scope">
                            {{ scope.row.comment }}
                        </template>
                    </el-table-column>

                    <el-table-column prop="quantity" :label="$t('message.quantity')"></el-table-column>

                    <el-table-column prop="tracking_code" :label="$t('message.tracking_code')">
                        <template slot-scope="scope">
                            {{ scope.row.tracking_code }}
                        </template>
                    </el-table-column>

                    <el-table-column prop="settings" :label="$t('message.settings')" width="80">
                        <template slot="header" slot-scope="scope">
                            <i class="el-icon-setting"></i>
                        </template>
                        <template slot-scope="scope">
                            <el-button @click="addSearchedProduct(scope.row)" type="primary" icon="el-icon-plus"></el-button>
                        </template>
                    </el-table-column>
                    
                </el-table>
            </div> 
        </el-dialog>

        <newDealRequestEmail
            v-if="permissions.some(per => per.slug == 'trackEmails.create')"
            :track_code="emailTrackCode"
        >
        </newDealRequestEmail>

        <showDealServices 
            :deal_id="deal_id_services"
        >
        </showDealServices>

        <updatePackageProductsWeight 
            :selectedPack="selectedPack" 
            @setProdWeightsInPack="setProdWeightsInPack"
        >
        </updatePackageProductsWeight>
    </div>
</template>
  
<script>
  import { mapGetters, mapActions } from "vuex";
  import drawer from "@/utils/mixins/drawer";
  import form from "@/utils/mixins/form";
  import selectDeal from "@/components/selects/select-deal";
  import selectFromFilial from "@/components/selects/select-from-filial";
  import selectToFilial from "@/components/selects/select-to-filial";
  import selectClient from "@/components/inventory/select-client";
  import selectContainerType from "@/components/inventory/select-container-type";
  import parcelProductsList from "@/views/waiting_product/components/parcel-products-list";
  import showDealServices from "@/views/services/show-deal-services";
  import updatePackageProductsWeight from "./update-package-products-weight";
  import newDealRequestEmail from "./new-deal-request-email";
  import product_calculation_functions from "@/utils/mixins/product_calculation_functions";
  import { successVoice, errorVoice } from '@/utils/audioHelper';
  
  import _ from 'lodash';
  
  export default {
    mixins: [form, drawer, product_calculation_functions],
    components: {
        selectDeal,
        selectFromFilial,
        selectToFilial,
        selectClient,
        selectContainerType,
        parcelProductsList,
        showDealServices,
        updatePackageProductsWeight,
        newDealRequestEmail
    },
    data() {
      return {
        deal_id_services: null,
        input2: '',
        search: '',
        mode: true,
        searchLoading: false,
        loadingButton: false,
        loadingData: false,
        dealProductList: [],
        selectedProductsForPacking: [],
        datePickerOptions: {
            disabledDate (date) {
                return date > new Date()
            }
        },
        dialogProductsList: false,
        loadingProducts: false,
        dialogParcelProductsList: false,
        searchedProductDialog: false,
        selectedProd: {},
        barcode_search: '',
        scanning: false,
        barcodeSearchProducts: [],
        selectedPack: {},
        selectedPackIndex: null,
        emailTrackCode: '',
      };
    },
    computed: {
        ...mapGetters({
            permissions: "auth/permissions",
            authUser: "auth/user",
            rules: "productCirculations/rules",
            model: "productCirculations/model",
            columns: "dealProducts/columns",
            dealProducts: "dealProducts/filialDealProducts",
            waitingPackageList: "packageForProductCirculations/waitingPackageList",
            packageDealProducts: "packageForProductCirculations/dealProducts",
        }),
        totalQuantity() {
            let sum = 0;
            if(this.dealProductList && this.dealProductList.length > 0){
                sum = this.dealProductList.reduce((accumulator, object) => {
                    return parseFloat(accumulator) + parseFloat(object.incoming_quantity);
                }, 0);
            }

            return sum;
        },
        totalCheckedQuantity() {
            let sum = 0;
            if(this.selectedProductsForPacking && this.selectedProductsForPacking.length > 0){
                sum = this.selectedProductsForPacking.reduce((accumulator, object) => {
                    return parseFloat(accumulator) + parseFloat(object.incoming_quantity);
                }, 0);
            }
            return sum;
        },
        watchForm() {
            return this.form.from_filial_id + this.form.all_client_id + this.form.deal_id;
        },
    },
    watch: {
        watchForm: {
            handler: async function(newVal, oldVal) {
                setTimeout(() => {
                    if(!this.scanning){
                        this.fetchData(this.form);
                    }
                }, 100);
            },
            immediate: true
        },
    },
    methods: {
        ...mapActions({
            save: "productCirculations/store",
            showDealProducts: "dealProducts/filialDealProducts",
            waitingPackages: "packageForProductCirculations/waitingPackages",
            showPackageDealProducts: "packageForProductCirculations/showPackageDealProducts",
            getProdOrPackByBarcode: "packageForProductCirculations/getProdOrPackByBarcode",
        }),

        updatePackProdsWeight(pack, index) {
            this.selectedPackIndex = index;
            this.selectedPack = pack;
            setTimeout(() => {
                this.selectedPack = {};
            }, 500)
        },

        setProdWeightsInPack(pack_products) {
            this.dealProductList[this.selectedPackIndex].products = JSON.parse(JSON.stringify(pack_products));
            this.dealProductList[this.selectedPackIndex].total_weight = JSON.parse(JSON.stringify(this.calcTotalWeight(pack_products)));
            this.selectedPackIndex = null;
        },

        calcTotalWeight(pack_products) {
            let totalWeight = pack_products.reduce((accumulator ,item) => {
                return accumulator += parseFloat(item.weight || 0);
            }, 0)
            return totalWeight;
        },

        showServices(deal_id) {
            this.deal_id_services = deal_id;
            setTimeout(() => {
                this.deal_id_services = null;
            }, 500);
        },

        afterOpen(){
            if(this.$refs['scanRef']){
                this.$refs['scanRef'].focus(); 
            }
        },
        scanClientProductBarcodeDebounce: _.debounce(
            async function(val) {
                let upper_barcode = val.toUpperCase();
                if(upper_barcode && upper_barcode.trim().length >= 3 ){
                    let exsiting_prod_index = await this.searchExistingProds(upper_barcode);
                    if(exsiting_prod_index >= 0){
                        await this.scanClientProductBarcode(true, exsiting_prod_index);
                    }else{
                        this.searchLoading = true;
                        await this.getProdOrPackByBarcode({barcode: upper_barcode, from_filial_id: this.form.from_filial_id})
                            .then(async res => {
                                if(res.status != 200){
                                    this.barcode_search = '';
                                    errorVoice();
                                    this.$alert(res);
                                }else{
                                    this.scanning = true;
                                    let prods = res.data.result.data.product;
                                    if(prods.length > 1){
                                        successVoice();
                                        this.showAllFindedProducts(prods);
                                    }else {
                                        if(!this.form.from_filial_id){
                                            this.form.from_filial_id = res.data.result.data.from_filial_id;
                                        }
                                        await this.scanClientProductBarcode(false, prods[0]);
                                    }
                                }
                                this.searchLoading = false;
                            }).catch(err => {
                                errorVoice();
                                this.searchLoading = false;
                                console.log(err, 'errrrr');
                            });
                    }         
                }
            }, 300), 

        async scanClientProductBarcode(existing, prodOrIdex){
            this.barcode_search = '';
            if(existing){
                let prod = this.dealProductList[prodOrIdex];

                if(prod.type == 'package' && prod.incoming_quantity == 0){
                    successVoice();
                    prod.incoming_quantity = prod.incoming_quantity + 1;
                }else if(prod.type == 'product' && (prod.quantity > (prod.old_incoming_quantity + prod.incoming_quantity))){
                    successVoice();
                    prod.incoming_quantity = prod.incoming_quantity + 1;
                }else{
                    this.insufficiant_prod_message();
                }
            }else{
                let existing_prod = this.dealProductList.filter(el => el.barcode == prodOrIdex.barcode);
                if(existing_prod.length > 0){
                    errorVoice();
                    this.$notify({
                        title: this.$t('message.product'),
                        type: "error",
                        offset: 130,
                        message: this.$t('message.product_already_exists')
                    });
                }else{
                    successVoice();
                    prodOrIdex.incoming_quantity = prodOrIdex.incoming_quantity + 1;
                    this.dealProductList.unshift(prodOrIdex);
                }
            }
        },

        showAllFindedProducts(prods){
            this.searchedProductDialog = true;
            this.barcodeSearchProducts = prods;
        },
        async addSearchedProduct(row){
            this.searchedProductDialog = false;
            if(!this.form.from_filial_id && row.from_filial){
                this.form.from_filial_id = row.from_filial ? row.from_filial.id : '';
            }
            await this.scanClientProductBarcode(false, row);   
        },

        tableRowClassName({row, rowIndex}){
            return ((row.weight && parseFloat(row.weight) > 0) || (row.total_weight && parseFloat(row.total_weight) > 0)) ? '' : 'mm_row_invalid_weight';
        },
        checkProductWeight(){
            let result = {
                success: true,
                message: 'done'
            };
            for (const item of this.form.products) {
                if(!((item.weight && parseFloat(item.weight) > 0) || (item.total_weight && parseFloat(item.total_weight) > 0))){
                    result.success = false;
                    result.message = this.$t('message.invalid_product_weight', {deal: item.deal_id});
                    break;
                }
            }
            return result;
        },
        searchExistingProds(val){
            let exsiting_prod = this.dealProductList.findIndex(el => (el.barcode == val || el.tracking_code == val));
            return exsiting_prod;
        },
        insufficiant_prod_message(){
            errorVoice();
            this.$notify({
                title: this.$t('message.product'),
                type: "error",
                offset: 130,
                message: this.$t('message.insufficient_goods_ordered')
            });
        },
        updateParcelProducts(parcel) {
            this.selectedProd = parcel;
            this.dialogParcelProductsList = true;
        },
        showPackProds(row){
            this.dialogProductsList = true;
            this.loadingProducts = true;
            this.showPackageDealProducts(row.id)
                .then(res => {
                    this.loadingProducts = false;
                }).catch(err => { this.loadingProducts = false; });
        },
        checkSelectedProds(){
            if(this.selectedProductsForPacking && this.selectedProductsForPacking.length > 0){
                let packs_count = 0;
                let deals = [];
                this.selectedProductsForPacking.forEach(element => {
                    packs_count += (element.type === 'package') ? 1 : 0;
                    if(!deals.includes(element.deal_id)){
                        deals.push(element.deal_id);
                    }
                });
                if(packs_count == 0 && deals.length == 1){
                    return true;
                }
            }
            return false;
        },
        updateCurrentQuantity(row){
            if(row.type === 'package'){
                if(row.incoming_quantity > 1){
                    row.incoming_quantity = 1;
                } 
            }else{
                if((row.quantity - row.old_incoming_quantity) < row.incoming_quantity){
                    this.$set(row, 'incoming_quantity', (row.quantity - row.old_incoming_quantity));
                    if(row.incoming_quantity > 0){
                        this.$refs.productTable.toggleRowSelection(row, true);
                    }
                }
                if(row.incoming_quantity > 0){
                        this.$refs.productTable.toggleRowSelection(row, true);
                }else{
                    this.$refs.productTable.toggleRowSelection(row, false);
                }
            }
            
        },
        async fetchData(query){
            if(query.from_filial_id && (query.deal_id || query.all_client_id)){
                this.loadingData = true;
                this.dealProductList = [];
                await this.waitingPackages({...query, recieve_from_client: true})
                    .then(res => {
                        let new_packs = JSON.parse(JSON.stringify(this.waitingPackageList));
                        this.dealProductList = this.dealProductList.concat(new_packs);
                    }).catch(err => {
                    });

                await this.showDealProducts({...query, recieve_from_client: true})
                    .then(res => {
                        let new_prods = JSON.parse(JSON.stringify(this.dealProducts));
                        this.dealProductList = this.dealProductList.concat(new_prods);
                        this.loadingData = false;
                    }).catch(err => {
                        this.loadingData = false;
                    });
            }else{
                this.dealProductList = [];
            }
        },
        handleSelectionChange(val) {
            this.selectedProductsForPacking = val;
        },
        
        submit(close = true, packing = false) {
            this.loadingButton = true;
            this.form.packing  = packing;
            let prod_list = this.dealProductList;
            if(packing){
                prod_list = this.selectedProductsForPacking;
            }
            this.form.products = prod_list.map(el => {
                return {
                    id: el.id, 
                    type: el.type, 
                    deal_id: el.deal_id, 
                    barcode: el.barcode, 
                    incoming_quantity: el.incoming_quantity,
                    weight: el.weight,
                    item_weight: el.item_weight,
                    total_weight: el.total_weight,
                    width: el.width,
                    height: el.height,
                    length: el.length,
                    products: el.products ? el.products : [],
                }
            });

            this.form.products = this.form.products.filter(el => el.incoming_quantity > 0);
            this.form.column_name = 'from_filial_id';
            this.form.store_in_realtion = 'Filial'
            let total = packing ? this.totalCheckedQuantity : this.totalQuantity;
            let weight_check = this.checkProductWeight();

            if(total > 0 && weight_check.success){
                this.$refs["form"].validate((valid) => {
                    if (valid) {
                        this.loadingButton = true;
                        this.save(this.form)
                            .then((res) => {
                                this.loadingButton = false;
                                this.$alert(res);
                                this.parent().listChanged();
                                if (close) {
                                    this.close();
                                }else{
                                    this.fetchData(this.form);
                                }
                                this.askBarcodePrinting();
                            })
                            .catch((err) => {
                                this.loadingButton = false;
                                this.$alert(err);
                            });
                    }else {
                        this.loadingButton = false;
                    }
                });
            }else if(!weight_check.success){
                this.loadingButton = false;

                this.$notify({
                    title: this.$t('message.warning'),
                    type: "warning",
                    offset: 130,
                    message: weight_check.message
                });
            }else{
                this.loadingButton = false;
                this.$notify({
                    title: this.$t('message.incoming_products'),
                    type: "error",
                    offset: 130,
                    message: this.$t('message.there is no incoming products check again')
                });
            }
        },
        askBarcodePrinting(){
            this.$emit('askBarcodePrinting');
        },
        afterLeave(){
            this.form = JSON.parse(JSON.stringify(this.model));
            this.dealProductList = [];
            this.scanning = false;
        }
    },
  };
  </script>
<style>
 .mm-input-without-padding .el-input .el-input__inner{
    padding: 1px;
 }
 .el-table .mm_row_invalid_weight {
    background-color: rgb(248, 210, 217);
 }
</style>